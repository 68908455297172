import styled from "styled-components";

export const Title = styled.h3<{ primaryColor?: any; showMargin?: any }>`
  color: ${(props) => props?.primaryColor};
  font-weight: 700;
  font-size: 25px;
  margin: ${(props) => (props?.showMargin ? "0 0 16px 0" : "revert")};
`;

export const SubTitle = styled.b<{ primaryColor?: any }>`
  color: ${(props) => props?.primaryColor};
  font-weight: 700;
  font-size: 15px;
`;

export const TextWrapper = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const CardContainer = styled.div`
  margin: 0px !important;

  @media (max-width: 576px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  @media (max-width: 576px) {
    max-width: 90% !important;
  }
`;

export const ProductsCard = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  box-shadow: 0 2px 2px rgb(36 38 38 / 20%);
  margin: 0 8px;
  padding: 10px;
  justify-content: space-between;

  @media (max-width: 576px) {
    max-width: 90% !important;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const PlayIcon = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
`;

export const VideoInfo = styled.div`
  display: none;
`;

export const PeopleImgDiv = styled.div<{
  imgPosition?: any;
  showCardBgBlur?: any;
  boxShadow?: any;
  backgroundImgUrl?: any;
}>`
  text-indent: -10000px;
  width: 100%;
  height: 100%;
  position: ${(props) => props.imgPosition};
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  aspect-ratio: auto;
  object-fit: ${(props) => props.showCardBgBlur};
  object-position: center;
  box-shadow: ${(props) => props.boxShadow};
  background-image: ${(props) => props.backgroundImgUrl};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const ImageDiv = styled.div<{
  imgPosition?: any;
  showCardBgBlur?: any;
  boxShadow?: any;
  backgroundImgUrl?: any;
}>`
  text-indent: -10000px;
  width: 100%;
  height: 100%;
  position: ${(props) => props.imgPosition};
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
  min-height: 130px;
  cursor: pointer;
  aspect-ratio: 4/2.7;
  object-fit: ${(props) => props.showCardBgBlur};
  object-position: center;
  box-shadow: ${(props) => props.boxShadow};
  background-image: ${(props) => props.backgroundImgUrl};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ImageDivContainer = styled.div<{
  backgroundImgUrl?: any;
}>`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: ${(props) => props.backgroundImgUrl};
`;

export const PeopleImageDivContainer = styled.div<{
  backgroundImgUrl?: any;
}>`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: ${(props) => props.backgroundImgUrl};
  height: 130px;
`;

export const Image = styled.img<{
  imgPosition?: any;
  showCardBgBlur?: any;
  boxShadow?: any;
  backgroundImgUrl?: any;
}>`
  text-indent: -10000px;
  width: 100%;
  height: 100%;
  position: ${(props) => props.imgPosition};
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
  min-height: 130px;
  cursor: pointer;
  aspect-ratio: 4/2.7;
  object-fit: ${(props) => props.showCardBgBlur};
  object-position: center;
  box-shadow: ${(props) => props.boxShadow};
  background-image: ${(props) => props.backgroundImgUrl};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ImageBg = styled.div<{
  backgroundImgUrl?: any;
}>`
  display: block;
  background: 50% 50% no-repeat;
  background-size: cover;
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  filter: blur(6px);
  opacity: 0.6;
  background-image: ${(props) => props.backgroundImgUrl};
`;

export const ImgIconContainer = styled.div<{
  primaryColor?: any;
}>`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.primaryColor};
  position: absolute;
  bottom: -7px;
  right: -7px;
  justify-content: center;
  align-items: center;
`;

export const CardTitle = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
  cursor: pointer;
  color: inherit;

  .author {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const Mtag = styled.span<{
  entitytagbackground?: any;
  entitytagfont?: any;
}>`
  vertical-align: 0.2em;
  background: ${(props) => props.entitytagbackground};
  color: ${(props) => props.entitytagfont};
  font-family: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-flex;
  min-height: 18px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  margin-right: 5px;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  display: flex;
  flex-flow: column;
  color: inherit;
`;

export const Loader = styled.div<{ primaryColor?: any }>`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid ${(props) => props.primaryColor};
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export const ProductTitle = styled.h3`
  font-family: inherit;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-right: 5px;
  color: inherit;
`;

export const ProductDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
`;

export const PopOver = styled.div<{ primaryColor?: any }>`
  color: ${(props) => props.primaryColor};
  font-weight: 700;
  font-size: 25px;
`;

export const CompanyLogo = styled.div`
  height: 50px;
  overflow: hidden;
  display: flex;
  margin: 0 0 10px;
  flex-grow: 2;
  align-items: end;

  .logo_card {
    padding: 5px 0 0;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ProdImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #dbe1e2;
  background: #c3d8e4;
  border-top: 1px solid #dbe1e2;
  border-radius: 0;
  margin-top: 8px;

  .cl-card__pict-bg {
    display: block;
    background: 50% 50% no-repeat;
    background-size: cover;
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    filter: blur(6px);
    opacity: 0.6;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: center;
  }
`;

export const IrisCard = styled.div`
  min-height: 100%;
  max-width: 100%;
  border: none;
  justify-content: space-around;
  display: flex;
  flex-flow: column;

  :hover {
    .video-img-container {
      opacity: 0.7;
      position: relative;
    }
    .play-button {
      display: block;
      opacity: 1;

      img {
        width: 50px;
        height: 50px;
      }
    }
    .video-insights {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 2rem;
      padding: 2px;
      background: white;
      opacity: 1;
    }
    .pointer {
      cursor: pointer;
    }
  }
`;

export const MspanBody = styled.div`
  display: flex;
  flex-flow: wrap;
  color: inherit;
`;

export const Mspan = styled.span`
  background: #f2f2f2;
  padding: 2px 5px;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  color: inherit;

  strong {
    margin-right: 4px;
    margin-bottom: 4px;
    color: inherit;
  }
`;

// People Entity stylings
export const PeopleImgContainer = styled.div`
  position: relative;
  text-align: center;
`;

export const PeopleImage = styled.img<{
  imgPosition?: any;
  boxShadow?: any;
  backgroundImgUrl?: any;
}>`
  // border-radius: 50%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  max-height: 200px;
  position: ${(props) => props.imgPosition};
  left: 0;
  object-fit: contain;
  min-height: 130px;
  cursor: pointer;
  margin-bottom: -2px;
  object-position: bottom;
  box-shadow: ${(props) => props.boxShadow};
  background-image: ${(props) => props.backgroundImgUrl};
  background-repeat: no-repeat;
`;

export const EntityMtag = styled.div<{
  entitytagbackground?: any;
  entitytagfont?: any;
}>`
  vertical-align: 0.2em;
  background: ${(props) => props.entitytagbackground};
  color: ${(props) => props.entitytagfont};
  cursor: pointer;
  justify-content: center;
  display: inline-flex;
  height: 45px;
  padding: 10px 5px;
  text-decoration: none;
  text-transform: uppercase;
`;

export const CompanyNameTag = styled.p`
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.5rem !important;
  flex: auto;
`;

export const PeopleCardTitle = styled.p`
  font-weight: 900;
  cursor: pointer;
  color: inherit;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const CircleImage = styled.div`
  position: relative;
  background: #eff6ff;

  .cism {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
`;

export const TitleTags = styled.span<{ showCursor?: any }>`
  position: absolute;
  bottom: -10px;
  right: -1.5px;
  cursor: ${(props) => props.showCursor};
  display: flex;
  flex-flow: column;
  align-items: end;
  transition: height 2s;
  justify-content: end;

  .first-img {
    width: 30px;
    margin-top: auto;
    margin-right: -1px;
  }

  :hover {
    height: 100%;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  :hover .spin.animated {
    display: inline-block;
    visibility: visible;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
`;

export const MultiDiv = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column-reverse;
  flex-grow: 1;
`;

export const MultiTag = styled.img<{
  animationDelay?: any;
}>`
  margin-bottom: 5px;
  display: none;
  visibility: hidden;
  width: 25px;
  animation-delay: ${(props) => props.animationDelay};
  -webkit-animation-delay: ${(props) => props.animationDelay};
`;

export const Notify = styled.div<{
  entitytagbackground?: any;
}>`
  font-family: auto;
  position: absolute;
  bottom: -5px;
  right: -1px;
  background: ${(props) => props.entitytagbackground};
  color: #fff;
  width: 17px;
  height: 17px;
  font-size: 12px;
  font-weight: 900;
  border-radius: 50%;
`;

export const TextWrapperPeople = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 2;
`;

// Banner Ads

export const BannerAds = styled.div`
  justify-content: center;
  display: flex;
`;

// Grid Ads

export const GridAds = styled.div<{
  columns: number;
  borderRadius: any;
  border: any;
  bordercolor: any;
  entityBackground: any;
  entitytagbordercolor: any;
  entityFont: any;
  dropShadow: any;
  imageWidth?: any;
  imageHeight?: any;
  titleweight?: any;
  authorstitleweight?: any;
  datetimebgcolor?: any;
  datetimefontcolor?: any;
  datetimefontsize?: any;
}>`
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .iiris-main-container-scroll {
    display: flex;
    flex-flow: column;

    &.horizontal-ad-content {
      width: 100%;
    }
  }

  .iiris-grid-ads-main-container {
    gap: 24px;
    display: flex;
    flex-flow: column;
    width: 100% !important;
    flex-wrap: wrap;

    @media only screen and (max-width: 743px) {
      .grid-row {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
      }
    }

    @media (min-width: 744px) and (max-width: 1023px) {
      .grid-row {
        grid-template-columns: ${(props) =>
          `repeat(${Math.max(
            props.columns / 2,
            2
          )}, minmax(0, 1fr))`} !important;
      }
    }

    @media only screen and (min-width: 1024px) {
      .grid-row {
        grid-template-columns: ${(props) =>
          `repeat(${props.columns}, minmax(0, 1fr))`} !important;
      }
    }

    @media only screen and (min-width: 1200px) {
      .grid-row,
      .iiris-grid-ads-main-container {
        gap: 32px !important;
      }
    }

    .grid-row {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      justify-content: center;
      gap: 24px;
      margin-right: 0 !important;
      margin-left: 0 !important;
      flex-wrap: nowrap !important;
    }

    .grid-row-horizontal {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      justify-content: center;
      gap: 24px;
      margin-right: 0 !important;
      margin-left: 0 !important;
      flex-wrap: nowrap !important;
    }

    .grid-col {
      display: flex;
      flex-flow: column;
      min-width: 180px !important;
      padding: 0 !important;

      .grid-col-container {
        border-radius: ${(props) => props.borderRadius};
        border: ${(props) =>
          props.border
            ? `1px solid ${props.bordercolor || "#000"}`
            : "1px solid #e9e9e9"};
        overflow: hidden;
        min-width: 180px;
        position: relative;
        background: #fff;
        transition: all 0.1s cubic-bezier(0, 0, 0.5, 1);
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-flow: column;

        .grid-col-tag {
          top: 0.5rem;
          left: 0.5rem;
          background: ${(props) =>
            props.entityBackground ? props.entityBackground : "#383434b5"};
          box-shadow:
            0 0 #0000,
            0 0 #0000,
            0px 3px 10px #00000029;
          text-transform: ${(props) =>
            props.border ? `capitalize` : "uppercase"};
          position: absolute;
          border-radius: ${(props) => props.borderRadius};
          border: ${(props) =>
            props.border
              ? `1px solid ${props.entitytagbordercolor || "#000"}`
              : "none"};
          padding: 4px 8px;
          color: ${(props) => (props.entityFont ? props.entityFont : "#fff")};
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: 12.5px;

          &:hover {
            box-shadow: ${(props) =>
              props.border
                ? `0 0 #0000, 0 0 #0000, 0px 6px 10px #00000052`
                : "none"};
            background: ${(props) =>
              props.border ? "#ffffff" : props.entityBackground || "#383434b5"};
            color: ${(props) =>
              props.border ? "#000000" : props.entityFont || "#fff"};
            border: ${(props) => (props.border ? `1px solid #ffffff` : "none")};
          }
        }

        .sponsored-tag {
          position: absolute;
          top: 0;
          right: 0;
          text-transform: uppercase;
          padding: 0.5rem 1rem;
          text-align: center;
          font-weight: 600;
        }

        .grid-image-container {
          display: flex;
          justify-content: center;
          background: ${(props) =>
            props.entityBackground ? props.entityBackground : "#fff"};
        }

        .grid-col-card {
          height: 100%;
          display: flex;

          &.grid-vertical {
            flex-flow: column;

            img {
              width: 100%;
              text-align: center;
              aspect-ratio: 16/9;
              object-fit: cover;
            }
          }

          &.grid-horizontal {
            flex-flow: row;

            .grid-col-tag {
              padding: 4px !important;
            }

            .grid-image-container img {
              box-shadow: ${(props) =>
                props.dropShadow
                  ? `5px 0px 0px ${props.dropShadow || "#000"}`
                  : "none"};
              object-fit: cover;
              width: ${(props) => props.imageWidth}px;
              height: ${(props) => props.imageHeight}px;
              min-width: ${(props) => props.imageWidth}px;
            }
          }

          .grid-vertical-content,
          .grid-horizontal-content {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            flex-grow: 1;
            font-family: inherit;
          }

          .grid-vertical-content {
            .grid-container-header {
              padding: 8px 8px 0.3em;
              font-weight: ${(props) => props.titleweight || 500};
              font-family: inherit;
              font-size: 16px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
              flex-grow: 1;
              color: ${(props) => (props.border ? `#000000` : "#333333")};
            }

            .grid-container-showby {
              padding: 2px 8px 12px;
              font-size: 12px;
              line-height: 15px;
              b {
                font-weight: ${(props) => props.authorstitleweight || 500};
                text-transform: capitalize;
              }
            }

            .grid-container-date {
              border-top: ${(props) =>
                props.border
                  ? `1px solid ${props.bordercolor || "#000"}`
                  : `2px solid #e9e9e9`};
              background: ${(props) =>
                props.datetimebgcolor ? props.datetimebgcolor : "#ffff"};
              padding: 8px;
              color: ${(props) =>
                props.datetimefontcolor ? props.datetimefontcolor : "#000"};
              font-weight: 400;
              line-height: 15px;
              font-size: ${(props) => props.datetimefontsize || 10}px;

              .grid-container-time {
                float: right;
                text-transform: capitalize;
              }

              .date-cont {
                padding: 2px;
              }
            }
          }

          .grid-horizontal-content {
            height: 100%;
            padding: 8px 12px;
            font-family: inherit;

            .grid-horizontal-card {
              margin-bottom: 4px;

              .key_name {
                overflow-wrap: anywhere;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-property:
                  color,
                  background-color,
                  border-color,
                  text-decoration-color,
                  fill,
                  stroke,
                  opacity,
                  box-shadow,
                  transform,
                  filter,
                  backdrop-filter,
                  -webkit-text-decoration-color,
                  -webkit-backdrop-filter;
                transition-duration: 0.1s;
                color: #666666;
                letter-spacing: 0;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 0.938rem;
                cursor: pointer;
              }
            }

            .grid-container-header {
              flex: 1 1 0%;
              .key_name {
                -webkit-line-clamp: 4;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                color: #000000;
                font-weight: ${(props) => props.titleweight || 500};
                font-size: 0.875rem;
                line-height: 1.203rem;
                text-overflow: ellipsis;
              }
            }

            .grid-container-showby {
              font-size: 0.75rem;
              line-height: 0.938rem;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              overflow: hidden;
              display: -webkit-box;
              margin-bottom: 5px;
              b {
                font-weight: ${(props) => props.authorstitleweight || 500};
                text-transform: capitalize;
              }
            }

            .grid-container-date {
              font-size: 0.75rem;
              line-height: 0.938rem;
              color: #666666;
            }
          }
        }
      }
    }
  }
`;

// PopUp Ad

export const PopUpAds = styled.div<{
  border: any;
  bordercolor: any;
  buttontopmargin: any;
  buttonrightmargin: any;
  showbuttonborder: any;
  bordersize: any;
  buttonsize: any;
  buttoncolor: any;
  buttonbordercolor: any;
  buttonfill: any;
  width: string;
  height: string;
}>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 6px 55px -10px rgba(0, 0, 0, 0.7);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: min(${window.innerWidth}px, ${(props) => props.width});
  max-height: 100dvh;
  border: ${(props) =>
    props.border ? `1px solid ${props.bordercolor}` : "none"};

  .iiris-popup-close-btn {
    position: absolute;
    top: ${(props) => props.buttontopmargin || 4}px;
    right: ${(props) => props.buttonrightmargin || 4}px;
    z-index: 10;

    .close-popup {
      color: ${(props) => props.buttoncolor || "inherit"};
      background-color: ${(props) => props.buttonfill || "transparent"};
      border-radius: 50%;
      cursor: pointer;
      width: ${(props) => props.buttonsize || "1.4"}rem;
      height: ${(props) => props.buttonsize || "1.4"}rem;
      border: ${(props) =>
        !props.showbuttonborder
          ? "none"
          : `${props.bordersize}rem solid ${
              props.buttonbordercolor || "inherit"
            }`};
    }
  }
`;

export const StickyPopUpAds = styled.div<{
  border: any;
  bordercolor: any;
  widgetposition: any;
  bottommargin: any;
  leftmargin: any;
  rightmargin: any;
  buttontopmargin: any;
  buttonrightmargin: any;
  showbuttonborder: any;
  bordersize: any;
  buttonsize: any;
  buttoncolor: any;
  buttonbordercolor: any;
  buttonfill: any;
  width: string;
  height: string;
}>`
  .right-in,
  .left-in,
  .bottom-in,
  .none {
    display: flex;
    align-items: stretch;
    position: fixed;
    border: ${(props) =>
      props.border ? `1px solid ${props.bordercolor}` : "none"};
    bottom: ${(props) =>
      props.widgetposition.includes("bottom")
        ? `${props.bottommargin || 0}px`
        : "auto"};
    z-index: 1000000;
    right: ${(props) =>
      props.widgetposition.includes("right")
        ? `${props.rightmargin || 0}px`
        : "auto"};
    left: ${(props) =>
      props.widgetposition.includes("left")
        ? `${props.leftmargin || 0}px`
        : props.widgetposition.includes("center")
        ? `${
            (window.innerWidth -
              Number(props.width.slice(0, props.width.length - 2))) /
              2 >
            0
              ? (window.innerWidth -
                  Number(props.width.slice(0, props.width.length - 2))) /
                2
              : 0
          }px`
        : "auto"};
    box-shadow: 0 6px 55px -10px rgba(0, 0, 0, 0.7);
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    max-width: min(${window.innerWidth}px, ${(props) => props.width});
    max-height: min(100dvh, ${(props) => props.height});
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-delay: 0s;
    animation-play-state: running;
    animation-fill-mode: forwards;

    .iiris-popup-close-btn {
      position: absolute;
      top: ${(props) => props.buttontopmargin || 4}px;
      right: ${(props) => props.buttonrightmargin || 4}px;
      z-index: 10;

      .close-popup {
        color: ${(props) => props.buttoncolor || "inherit"};
        background-color: ${(props) => props.buttonfill || "transparent"};
        border-radius: 50%;
        cursor: pointer;
        width: ${(props) => props.buttonsize || "1.4"}rem;
        height: ${(props) => props.buttonsize || "1.4"}rem;
        border: ${(props) =>
          !props.showbuttonborder
            ? "none"
            : `${props.bordersize}rem solid ${props.buttonbordercolor}`};
      }
    }
  }

  .bottom-in {
    animation-name: silde_to_top;
  }

  .left-in {
    animation-name: silde_to_right;
  }

  .right-in {
    animation-name: silde_to_left;
  }

  @keyframes silde_to_top {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: ${(props) => props.bottommargin || 0}px;
      z-index: 1000000;
      opacity: 1;
    }
  }

  @keyframes silde_to_right {
    0% {
      left: -100%;
    }
    100% {
      left: ${(props) => props.leftmargin || 0}px;
      z-index: 1000000;
      opacity: 1;
    }
  }

  @keyframes silde_to_left {
    0% {
      right: -100%;
    }
    100% {
      right: ${(props) => props.rightmargin || 0}px;
      z-index: 1000000;
      opacity: 1;
    }
  }
`;

export const BannerContainer = styled.div<{
  maxWidth: any;
  mediaPosition: any;
  fontSize1: any;
  fontSize2: any;
  background: any;
}>`
  overflow: hidden;
  margin: 1px;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.maxWidth}px;
  align-items: ${(props) =>
    ["left", "right"].includes(props.mediaPosition) ? "center" : "initial"};

  .iiris-banner-imagecontainer {
    position: relative;
    display: flex;
    order: initial;

    @media (min-width: 768px) {
      order: ${(props) =>
        ["bottom", "right"].includes(props.mediaPosition) ? "2" : "initial"};
    }
  }

  .iiris-banner-contentcontainer {
    .content-heading {
      font-size: ${(props) => props.fontSize1}px;
    }

    .content-description {
      font-size: ${(props) => props.fontSize2}px;
    }
  }

  .iiris-banner-imagecontainer::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      90deg,
      ${(props) => props.background} 3%,
      rgba(29, 45, 53, 0) 30%,
      rgba(29, 45, 53, 0) 70%,
      ${(props) => props.background} 97.5%
    );
  }

  @media (min-width: 768px) {
    flex-direction: ${(props) =>
      ["top", "bottom"].includes(props.mediaPosition) ? "column" : "row"};
    align-items: initial;
  }
`;

export const StickyBannerContainer = styled.div<{
  maxWidth: any;
  widgetposition: any;
  mediaPosition: any;
  fontSize1: any;
  fontSize2: any;
  background: any;
}>`
  overflow: hidden;
  position: fixed;
  margin: 1px;
  max-width: ${(props) => props.maxWidth}px;
  z-index: 100000;
  bottom: ${(props) =>
    props.widgetposition.includes("bottom") ? "0" : "auto"};
  right: ${(props) => (props.widgetposition.includes("right") ? "0" : "auto")};
  left: ${(props) => (props.widgetposition.includes("left") ? "0" : "auto")};

  a {
    display: flex;
    flex-direction: ${(props) =>
      ["top", "bottom"].includes(props.mediaPosition) ? "column" : "row"};
  }

  .iiris-banner-imagecontainer {
    position: relative;
    display: flex;
  }

  .iiris-banner-contentcontainer {
    .content-heading {
      font-size: ${(props) => props.fontSize1}px;
    }

    .content-description {
      font-size: ${(props) => props.fontSize2}px;
    }
  }

  .iiris-banner-imagecontainer::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      90deg,
      ${(props) => props.background} 3%,
      rgba(29, 45, 53, 0) 30%,
      rgba(29, 45, 53, 0) 70%,
      ${(props) => props.background} 97.5%
    );
  }
`;
//End of Styling
