export const getConvertedWatchTime = (time: number) => {
  time = Math.floor(time);
  const hours = Math.floor(time / 3600);
  time = time % 3600;
  const minutes = Math.floor(time / 60);
  time = time % 60;
  let formattedTime = "";
  if (hours > 0) {
    if (hours > 9) formattedTime += `${hours}:`;
    else formattedTime += `0${hours}:`;
  }
  if (minutes > 0) {
    if (minutes > 9) formattedTime += `${minutes}:`;
    else formattedTime += `0${minutes}:`;
  }
  if (time > 9) formattedTime += time;
  else formattedTime += `0${time}`;
  return formattedTime;
};

export const getParameterizedImageUrl = (
  imageUrl: string,
  cols: number,
  layout?: string
) => {
  if (!layout) {
    layout = "vertical";
  }
  if (imageUrl.includes("contentstack")) {
    let idealWidth;
    if (layout === "horizontal") {
      idealWidth = 200;
    }
    if (cols == 2 && layout === "vertical") {
      idealWidth = 400;
    }
    return `${imageUrl}?width=${idealWidth}&auto=webp&quality=95&format=jpg&disable=upscale`;
  }
  return imageUrl;
};

export function positionSponsored(
  articles: any[],
  sponsoredPositions: string,
  showSponsored: string,
  additionalContentPosition: string,
  additionalContentType: string
): any[] {
  if (!showSponsored && !additionalContentPosition) return articles;
  try {
    const positions = sponsoredPositions
      .split(",")
      .map((pos) => Number(pos.trim()));
    if (!positions.length) return articles;
    const sponsoredArticles = [];
    //Get the sponsored articles
    for (let i = 0; i < articles.length; i++) {
      if (articles[i]._source?.is_sponsored === "true") {
        if (
          additionalContentType &&
          articles[i]._source?.type === additionalContentType
        )
          continue;
        sponsoredArticles.push(articles[i]);
      }
    }
    //Position the sponsored articles
    const formattedArticles = Array(articles.length).fill(null);
    for (let i = 0; i < positions.length; i++) {
      if (sponsoredArticles[i] && showSponsored) {
        formattedArticles[positions[i] - 1] = sponsoredArticles[i];
      }
    }
    //Position the Additional Content Type
    if (additionalContentPosition) {
      let additionalContent;
      for (let i = 0; i < articles.length; i++) {
        if (articles[i]._source?.type === additionalContentType) {
          additionalContent = articles[i];
          additionalContent._source.is_sponsored = "false";
          break;
        }
      }
      formattedArticles[parseInt(additionalContentPosition) - 1] =
        additionalContent;
    }
    //Position the non-sponsored articles
    for (let i = 0; i < articles.length; i++) {
      const isSponsored = articles[i]._source?.is_sponsored === "true";
      const additionalcontentType = additionalContentPosition
        ? articles[i]._source?.type === additionalContentType
        : false;
      if (!isSponsored && !additionalcontentType) {
        const idx = formattedArticles.findIndex(
          (val) => val === null || val === undefined
        );
        if (idx >= 0) {
          formattedArticles[idx] = articles[i];
        }
      }
    }
    return formattedArticles.filter(Boolean);
  } catch (err) {
    console.log(err);
    return articles;
  }
}
export const underscoreWrapped = /(?<=(?:\s|^)(?![@#])[^_\n]*)_([^_]+)_/g;

export const getNewDimensions = (
  width: number,
  height: number,
  selected: number
): number[] => {
  const newWidth = width * (window.innerWidth / selected) || 0;
  const newHeight = height * (window.innerWidth / selected) || 0;
  return [Math.floor(newWidth), Math.floor(newHeight)];
};
