import { Icon } from "@iconify/react";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import CarouselOneByOne from "react-elastic-carousel";
import SignerService from "../../services/SignerService";
import { removeTags, showFormattedDate } from "../../services/utils";
import ContentUnavailable from "../ContentUnavailable";
import { ContentEntity, ContentImage } from "../entity/content";
import { PeopleEntity, PeopleImage } from "../entity/people";
import { VideoEntity, VideoImage } from "../entity/video";
import * as S from "../style";

import "../iris-recommend.css";

let sendAdImp = true;

const CarouselWidget: React.FC<any> = (props) => {
  const {
    config,
    noOfcardItems,
    cardMaxWidth,
    widgetLayout,
    layoutStyling,
    cardStyling,
    apiDomain,
  } = props;
  const primaryColor = config.skin;
  const [carouselItems, setCarouselItems] = useState<any[][]>([]);
  const [showSubTitleScroll, setshowSubTitleScroll] = useState(true);
  const [index, setIndex] = useState(0);
  const ref2 = useRef<any>(null);
  const [recommendationData, setRecommendationData] = useState<any[]>([]);
  const [recommendationDataLoaded, setRecommendationDataLoaded] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getRecommendations = async () => {
    let url = "";
    if (config.recipe) {
      url = `${apiDomain}/v3/recommend/public/`;
      if (config.entity) {
        url += `${config.entity === "video" ? "content" : config.entity}/`;
      }
      if (config.recipe) {
        url += `${config.recipe}/`;
      }
      if (["popular-items", "recent-items"].includes(config.recipe)) {
        if (config.by) {
          url += `${config.by}/`;
        }
      } else if (config.variant) {
        url += `${config.variant}/`;
      }
      if (config.division) {
        url += `${config.division}/`;
      }
      if (config.brand_family) {
        url += `${config.brand_family}/`;
      }
      if (config.brand) {
        url += `${config.brand}`;
      }
    } else {
      url = `${apiDomain}/v2/recommend/public/${
        config.entity === "product" ? "products" : "content"
      }/${
        config.entity === "product" ? config.marketType : config.brand_family
      }`;
      if (config.entity === "product") {
        url = url + "/" + config.brand;
      } else if (config.entity === "content") {
        url = url + "/" + config.site;
      }
    }

    const recommendUrl = new URL(url);
    if (config.recipe) {
      if (["popular-items", "recent-items"].includes(config.recipe)) {
        if (config.category || config.author || config.content_type) {
          recommendUrl.searchParams.set(
            "value",
            encodeURIComponent(
              config.category || config.author || config.content_type
            )
          );
        }
      } else if (config.itemUrl && config.recipe !== "user") {
        let itemUrl = config.itemUrl.replace(/\s/g, "");
        itemUrl = itemUrl.split("?");
        recommendUrl.searchParams.set("item", itemUrl[0]);
      }
      if (config.limit) {
        recommendUrl.searchParams.set("limit", config.limit);
      }
    } else {
      if (config.entity === "content") {
        recommendUrl.searchParams.set("attribute", "url");
      }
      if (config.entity === "product") {
        //hotfix - by category should be most-viewed
        //recommendUrl.searchParams.set("type", 'more-like');
        if (config.by != "") {
          recommendUrl.searchParams.set("by", config.by);
          if (config.by === "category") {
            recommendUrl.searchParams.set("type", "most-viewed");
          } else {
            recommendUrl.searchParams.set("type", "more-like");
          }
        }
      } else {
        if (config.type != "") {
          recommendUrl.searchParams.set("type", config.type);
        }
      }
      if (config.term != "") {
        const newTerm = config.term.replace(/\s/g, "");
        recommendUrl.searchParams.set("term", newTerm);
      }
      if (config.period != "") {
        recommendUrl.searchParams.set("period", config.period);
      }
      if (config.limit != "") {
        recommendUrl.searchParams.set("limit", config.limit);
      }
    }
    if (config.showsponsored === "yes") {
      recommendUrl.searchParams.set("num_sp_item", config.sponsoredcount);
    }
    recommendUrl.searchParams.set("mode", "db");
    config.maxAge !== ""
      ? recommendUrl.searchParams.set("item_age", config.maxAge)
      : recommendUrl.searchParams.set("item_age", "12");

    //Get Recommendations
    if (config.recipe !== "user") {
      SignerService.signedAPIRequest(
        "execute-api",
        "GET",
        recommendUrl.toString(),
        ""
      ).then(function (jsonData) {
        setIsLoading(false);
        setRecommendationDataLoaded(true);
        const data = _.orderBy(
          jsonData,
          "_source.site_published_time",
          "desc"
        ).slice(0, +config.totalCards);
        setRecommendationData(data);
      });
    } else {
      const body: any = {
        id: config.itemValue,
        mode: config.mode,
        limit: config.entity === "product" ? 25 : config.limit,
        filter_value: config.filterValue
          ? config.filterValue.split(",").map((it: string) => it.trim())
          : [],
        is_entitled: config.isEntitled ? "true" : "false",
      };
      if (config.showsponsored !== "yes") {
        body["num_of_sponsored_item"] = parseInt(config.sponsoredcount);
      }
      SignerService.m2mSignedAPIRequest(
        `${apiDomain}/v3/recommend/content/user/${config.division}/${config.brand_family}/${config.brand}`,
        body
      )
        .then((jsonData) => {
          setIsLoading(false);
          setRecommendationDataLoaded(true);
          const data = _.orderBy(
            jsonData?.data[0]?.recommendations,
            "_source.site_published_time",
            "desc"
          ).slice(0, +config.totalCards);
          setRecommendationData(data);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  //Functions to check if any of the recommendation element is present in viewport
  const isInViewport = (elements: NodeListOf<HTMLElement>) => {
    let result = false;
    for (const element of elements) {
      result = checkIsInViewPort(element) || result;
      if (result) {
        break;
      }
    }
    return result;
  };

  const checkIsInViewPort = (elem: HTMLElement) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  function getRedirectMode() {
    const redirectionMode = config.redirectMode;
    let mode;
    if (redirectionMode === "new") {
      mode = "_blank";
    } else if (redirectionMode === "same") {
      mode = "_self";
    }
    return mode;
  }

  function addClickImpression(sourceUrl: string) {
    let redirectUrl;
    try {
      redirectUrl = new URL(sourceUrl);
      redirectUrl.searchParams.append("iiris-ref", "recommend");
      redirectUrl.searchParams.append("placement", config.placement);
      redirectUrl.searchParams.append("widgetId", config.id);
      redirectUrl.searchParams.append("recipe", config.recipe);
    } catch (e) {
      redirectUrl =
        sourceUrl +
        `?iiris-ref=recommend&placement=${config.placement}&widgetId=${config.id}&recipe=${config.recipe}`;
    }
    // Ad Impression Event OnClick
    adImpression("click", redirectUrl.toString());
  }

  //Adding scrollSubtitle Scroll css
  const subTitleScrollStyle = () => {
    if (config.scrollPosition === "center") {
      return {};
    }

    const subTitleScrollCss = {
      height: "30px",
      display: "flex",
      justifyContent: `${config.entity === "video" ? "end" : "space-between"}`,
    };
    return subTitleScrollCss;
  };

  //Tracking Ad Impressions
  const adImpression = (zone: string, redirectUrl?: string) => {
    if (window.IIRISTracker && window.IIRISTracker.trackImpression) {
      if (zone === "click") {
        const ctrEvt = new CustomEvent("recommendCTREvent", {
          detail: { target_url: redirectUrl },
        });
        window.dispatchEvent(ctrEvt);
      }
      const impressionId =
        window.location.host +
        "-" +
        Date.now() +
        "&iiris-ref=recommend&recipe=" +
        config.recipe;
      const bannerId = "iiris" + Date.now();
      const campaignId = "iiris_recommend";
      const advertiserId = "iiris";
      const targetUrl = window.location.href;

      const data = {
        impressionId: impressionId,
        bannerId: bannerId,
        campaignId: campaignId,
        advertiserId: advertiserId,
        targetUrl: targetUrl,
        zoneId: zone,
      };

      if (zone === "viewport") {
        const viewportEvt = new CustomEvent("iiris_recommendViewportEvent", {
          detail: { impressionId: impressionId },
        });
        window.dispatchEvent(viewportEvt);
      }

      window.IIRISTracker.trackImpression(data);
    }
  };

  //Tracking Viewport event for ad impression
  window.addEventListener(
    "scroll",
    function () {
      if (sendAdImp) {
        if (
          isInViewport(this.document.querySelectorAll("#carousel_container"))
        ) {
          // Ad Impression event when recommendation is seen by the user
          sendAdImp = false;
          adImpression("viewport");
        }
      }
    },
    false
  );

  useEffect(() => {
    //Recommendation API
    if (recommendationData.length == 0 && !recommendationDataLoaded) {
      getRecommendations();
    }
    // Carousel
    const arr = [];
    const newRecommendationData = [...recommendationData];
    setshowSubTitleScroll(
      newRecommendationData.length > noOfcardItems ? true : false
    );
    while (newRecommendationData.length > 0) {
      arr.push(newRecommendationData.splice(0, noOfcardItems));
    }
    setCarouselItems(arr);
  }, [recommendationData, recommendationDataLoaded]);

  const handleSelect = (selectedIndex: any, _e: any) => {
    setIndex(selectedIndex);
    adImpression("scroll");
  };

  const onNextContainerClick = () => {
    ref2?.current?.next();
  };

  const onPrevContainerClick = () => {
    ref2?.current?.prev();
  };

  const onNextClick = () => {
    if (index < recommendationData.length) {
      setIndex(
        ref2.current.getNextItemIndex(ref2.current.state.activeIndex, false)
      );
      ref2.current.slideNext();
    }
  };

  const onPrevClick = () => {
    if (index > 0) {
      setIndex(ref2.current.state.activeIndex - 1);
      ref2.current.slidePrev();
    }
  };

  const waitUntilElementVisible = async (queryName: string) =>
    new Promise((resolve, _reject) => {
      const existCondition = setInterval(function () {
        const elem = document.querySelector(queryName);
        if (elem) {
          clearInterval(existCondition);
          resolve(true);
        }
      }, 1000);
    });

  const handleHeight = () => {
    if (widgetLayout == "vertical" && recommendationData.length) {
      waitUntilElementVisible("#carousel_container .rec-carousel").then(() => {
        setTimeout(() => {
          const eachVisibleTabs = document.querySelectorAll(
            "#carousel_container .one-by-one"
          );
          const maxHeight = [];
          for (let i = 0; i < eachVisibleTabs.length; i++) {
            maxHeight.push(eachVisibleTabs[i].clientHeight);
          }
          if (maxHeight.length) {
            for (let i = 0; i < eachVisibleTabs.length; i++) {
              (eachVisibleTabs[i] as HTMLElement).style.height = `${Math.max(
                ...maxHeight
              )}px`;
            }
            (
              document.querySelector(
                "#carousel_container .rec-carousel"
              ) as HTMLElement
            ).style.height = `${Math.max(...maxHeight) * noOfcardItems}px`;
          }
        });
      });
    }
  };

  const RenderBody = ({ c }: { c: any }) => {
    const bodyArray = [
      config.entity === "people" ? (
        <PeopleEntity
          key={`${c._id}-0`}
          c={c}
          primaryColor={primaryColor}
          config={config}
          removeTags={removeTags}
          showFormattedDate={showFormattedDate}
          getRedirectMode={getRedirectMode}
          addClickImpression={addClickImpression}
        />
      ) : config.entity === "video" ? (
        <VideoEntity
          key={`${c._id}-0`}
          c={c}
          primaryColor={primaryColor}
          config={config}
          removeTags={removeTags}
          showFormattedDate={showFormattedDate}
          getRedirectMode={getRedirectMode}
          addClickImpression={addClickImpression}
        />
      ) : (
        <ContentEntity
          key={`${c._id}-0`}
          c={c}
          primaryColor={primaryColor}
          config={config}
          removeTags={removeTags}
          showFormattedDate={showFormattedDate}
          getRedirectMode={getRedirectMode}
          addClickImpression={addClickImpression}
        />
      ),
    ];
    bodyArray.splice(
      config.entityImagePosition === "bottom" ? 1 : 0,
      0,
      config.entity === "people" ? (
        <PeopleImage
          key={`${c._id}-1`}
          c={c}
          primaryColor={primaryColor}
          config={config}
          getRedirectMode={getRedirectMode}
          addClickImpression={addClickImpression}
        />
      ) : config.entity === "video" ? (
        <VideoImage
          key={`${c._id}-1`}
          c={c}
          primaryColor={primaryColor}
          config={config}
          getRedirectMode={getRedirectMode}
          addClickImpression={addClickImpression}
        />
      ) : (
        <ContentImage
          key={`${c._id}-1`}
          c={c}
          primaryColor={primaryColor}
          config={config}
          getRedirectMode={getRedirectMode}
          addClickImpression={addClickImpression}
        />
      )
    );
    return <>{bodyArray}</>;
  };

  const RenderScrollControls = () => (
    <div className="iiris-col-12" style={subTitleScrollStyle()}>
      <div
        className={
          (config.scrollType == "onebyone" && index === 0 ? "disabled" : "") +
          " text-left"
        }
      >
        {config.entity === "video" && index === 0 ? null : (
          <a
            // href={"#" + config.id}
            role="button"
            data-slide="next"
            //Ad Impression OnClick of widget Scroll
            onClick={
              config.scrollType == "onebyone"
                ? onPrevClick
                : onPrevContainerClick
            }
          >
            <Icon
              icon={`fa:arrow-circle-o-${
                widgetLayout == "vertical" ? "up" : "left"
              }`}
              style={{
                color: primaryColor,
                fontSize: "23px",
                verticalAlign: "bottom",
                marginRight: "10px",
              }}
            />
          </a>
        )}
        <S.SubTitle primaryColor={primaryColor}>{config.subTitle}</S.SubTitle>
      </div>
      <div
        className={`text-right${
          config.scrollType == "onebyone" &&
          index + noOfcardItems >= recommendationData.length
            ? " disabled"
            : ""
        }`}
      >
        <S.SubTitle primaryColor={primaryColor}>{config.subTitle}</S.SubTitle>
        {config.entity === "video" &&
        index + noOfcardItems >= recommendationData.length ? null : (
          <a
            // href={"#" + config.id}
            role="button"
            data-slide="prev"
            //Ad Impression OnClick of widget Scroll
            onClick={
              config.scrollType == "onebyone"
                ? onNextClick
                : onNextContainerClick
            }
          >
            <Icon
              icon={`fa:arrow-circle-o-${
                widgetLayout == "vertical" ? "down" : "right"
              }`}
              style={{
                color: primaryColor,
                fontSize: "23px",
                verticalAlign: "bottom",
                marginLeft: "10px",
              }}
            />
          </a>
        )}
      </div>
    </div>
  );

  return (
    <div className="iiris-container">
      <div className="iiris-row" style={{ position: "relative" }}>
        <div className="iiris-col-12">
          <S.Title
            primaryColor={primaryColor}
            className={
              widgetLayout == "vertical" && config.scrollPosition === "center"
                ? "iiris-mb-4"
                : "iiris-mb-3"
            }
          >
            {config.title}
          </S.Title>
        </div>
        {showSubTitleScroll && config.scrollPosition === "top" && (
          <RenderScrollControls />
        )}
        <div className="iiris-col-12">
          {isLoading && (
            <S.SpinnerContainer>
              <S.Loader primaryColor={primaryColor}></S.Loader>
            </S.SpinnerContainer>
          )}
          {!isLoading && (
            <div className={`iiris-${config.entity}-type container-fluid`}>
              <div className="carousel-inner" id="carousel_container">
                <div
                  className="carousel-wrapper"
                  onMouseEnter={() => {
                    adImpression("onMouseEnter");
                  }}
                >
                  {config.scrollType == "onebyone" ? (
                    <CarouselOneByOne
                      ref={ref2}
                      isRTL={false}
                      id={config.id}
                      itemsToShow={noOfcardItems}
                      pagination={false}
                      verticalMode={widgetLayout == "vertical"}
                      initialActiveIndex={index}
                      autoTabIndexVisibleItems={false}
                      enableAutoPlay={config.scrollInterval > 0}
                      autoPlaySpeed={Number(config.scrollInterval)}
                      showArrows={showSubTitleScroll}
                      disableArrowsOnEnd={true}
                      className={
                        config.scrollPosition === "center"
                          ? ""
                          : "iiris-disable_center_buttons"
                      }
                      enableMouseSwipe={true}
                      onResize={() =>
                        widgetLayout == "vertical" ? handleHeight() : null
                      }
                      onChange={() =>
                        widgetLayout == "vertical" ? handleHeight() : null
                      }
                      onNextEnd={(
                        _nextItemObject,
                        _currentPageIndex: number
                      ) => {
                        adImpression("scroll");
                        setIndex(ref2.current.state.activeIndex);
                      }}
                      onPrevEnd={(
                        _nextItemObject,
                        _currentPageIndex: number
                      ) => {
                        adImpression("scroll");
                        setIndex(ref2.current.state.activeIndex);
                      }}
                    >
                      {recommendationData.length == 0 ? (
                        <ContentUnavailable />
                      ) : (
                        recommendationData.map((c, i) => (
                          <S.CardContainer
                            key={c._id}
                            className={layoutStyling + " one-by-one"}
                          >
                            <S.Card
                              key={i}
                              className={cardStyling}
                              style={{
                                maxWidth: cardMaxWidth,
                                height: "100%",
                                padding:
                                  config.entity === "people" ? "0 5px" : "none",
                              }}
                            >
                              <S.IrisCard
                                className="iiris-card"
                                style={{
                                  paddingBottom:
                                    config.entityImagePosition === "bottom"
                                      ? "40px"
                                      : 0,
                                  width: "100vw",
                                }}
                              >
                                <RenderBody key={c._id} c={c} />
                              </S.IrisCard>
                            </S.Card>
                          </S.CardContainer>
                        ))
                      )}
                    </CarouselOneByOne>
                  ) : (
                    <Carousel
                      activeIndex={index}
                      onSelect={handleSelect}
                      indicators={false}
                      controls={
                        showSubTitleScroll && config.scrollPosition === "center"
                      }
                      ref={ref2}
                      bsPrefix={config.scrollInterval > 0 ? "carousel" : ""}
                      interval={
                        config.scrollInterval > 0 ? config.scrollInterval : null
                      }
                      id={config.id}
                      keyboard={true}
                      className={
                        (widgetLayout == "vertical" ? "carousel-fade" : "") +
                        (widgetLayout == "vertical" &&
                        config.scrollPosition === "center"
                          ? " scroll-top-bottom"
                          : "")
                      }
                    >
                      {carouselItems.length == 0 ? (
                        <ContentUnavailable />
                      ) : (
                        carouselItems.map((item, i) => (
                          <Carousel.Item key={i} bsPrefix="carousel-item">
                            <S.CardContainer
                              className={
                                layoutStyling +
                                (widgetLayout === "vertical"
                                  ? " position-center"
                                  : "")
                              }
                            >
                              {item.map((c, i) => (
                                <S.Card
                                  key={i}
                                  className={
                                    cardStyling +
                                    " iiris-carousel-container-items"
                                  }
                                  style={{
                                    maxWidth: cardMaxWidth,
                                    padding:
                                      config.entity === "people"
                                        ? "0 5px"
                                        : "none",
                                  }}
                                >
                                  <S.IrisCard
                                    className="iiris-card"
                                    style={{
                                      paddingBottom:
                                        config.entityImagePosition === "bottom"
                                          ? "40px"
                                          : 0,
                                      width: "100vw",
                                    }}
                                  >
                                    <RenderBody key={c._id} c={c} />
                                  </S.IrisCard>
                                </S.Card>
                              ))}
                            </S.CardContainer>
                          </Carousel.Item>
                        ))
                      )}
                    </Carousel>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {showSubTitleScroll && config.scrollPosition === "bottom" && (
          <RenderScrollControls />
        )}
      </div>
    </div>
  );
};

export { CarouselWidget };
