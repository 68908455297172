import React from "react";

const GridProducts: React.FC<any> = (props) => {
  return (
    <div className="recommended-products">
      <ol>
        {props.gridData.map((item: any) => (
          <li
            key={item.product_name}
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {item.product_name}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default GridProducts;
