import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentUnavailable: React.FC<any> = () => (
  <Container className="iiris-container">
    <h5>Sorry! No recommendations to show</h5>
  </Container>
);

export default ContentUnavailable;
