import { Icon } from "@iconify/react";
import * as lookup from "country-code-lookup";
import parser from "html-react-parser";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ReactCountryFlag from "react-country-flag";
import * as S from "../style";

import React from "react";
import { underscoreWrapped } from "../helper";
import "../iris-recommend.css";

const ContentEntity: React.FC<any> = (props) => {
  const {
    c,
    config,
    removeTags,
    showFormattedDate,
    getRedirectMode,
    addClickImpression,
  } = props;
  const country = c._source?.company?.country;
  let country_name = c._source?.company?.country?.split(" ");
  if (country_name) {
    country_name = country_name
      .map((e: any) => e?.[0]?.toUpperCase() + e?.slice(1)?.toLowerCase())
      .join(" ");
  }

  const companyUrl = c._source?.company?.company_profile_link
    ? c._source?.company?.company_profile_link
    : "";

  return (
    <S.CardBody
      key={c._id}
      style={{
        padding: config.entityImagePosition === "bottom" ? "0" : "25px 0 0",
        flexGrow: 1,
        color: "inherit",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <S.CardTitle
        key={c._id}
        data-toggle="tooltip"
        data-placement="bottom"
        title={
          c._source.name
            ? removeTags(c._source.name)
            : parser(
                removeTags(c._source.title).replace(
                  underscoreWrapped,
                  "<em>$1</em>"
                )
              )
        }
      >
        {config.showEntityTag === "yes" ? (
          <a
            href={c._source.url}
            target={getRedirectMode()}
            onClick={() => addClickImpression(c._source.url)}
            className="a-none"
          >
            <S.Mtag
              className="m-tag"
              entitytagbackground={config.entityTagBackground}
              entitytagfont={config.entityTagFont}
            >
              {config.entity}
            </S.Mtag>
          </a>
        ) : null}
        <a
          href={c._source.url}
          target={getRedirectMode()}
          onClick={() => addClickImpression(c._source.url)}
          className="a-none"
        >
          <span>
            {c._source.name
              ? removeTags(c._source.name)
              : parser(
                  removeTags(c._source.title).replace(
                    underscoreWrapped,
                    "<em>$1</em>"
                  )
                )}
          </span>
        </a>
      </S.CardTitle>
      <a
        href={c._source.url}
        target={getRedirectMode()}
        onClick={() => addClickImpression(c._source.url)}
        className="a-none"
      >
        <S.TextWrapper
          data-toggle="tooltip"
          data-placement="bottom"
          title={removeTags(c._source.description)}
        >
          {removeTags(c._source.description)}
        </S.TextWrapper>
      </a>
      {config.showDate === "yes" ? (
        <p className="iiris-card-text" style={{ marginBottom: "10px" }}>
          <small className="iiris-text-muted">
            {showFormattedDate(
              c._source.date,
              c._source.site_published_date,
              config.dateFormat
            )}
          </small>
        </p>
      ) : null}
      {config.showCompanyLogo === "yes" &&
        c._source?.company?.company_thumbnail && (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            overlay={
              config.showCompanyLogo ? (
                <Popover id={"popover-contained"} style={{ margin: 0 }}>
                  <Popover.Body style={{ margin: 0 }}>
                    <h5 style={{ marginBottom: "15px" }}>
                      {(c._source?.company?.name || "").toUpperCase()}
                    </h5>
                    <S.MspanBody>
                      {country ? (
                        <S.Mspan>
                          <ReactCountryFlag
                            countryCode={
                              lookup.byCountry(country_name)?.internet || ""
                            }
                            svg
                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                            cdnSuffix="svg"
                            style={{ marginRight: "2px", marginBottom: "2px" }}
                          />
                          <span style={{ margin: "1px 0 0 3px" }}>
                            {country}
                          </span>
                        </S.Mspan>
                      ) : (
                        ""
                      )}
                      {c._source?.company?.years_with_cphi ? (
                        <S.Mspan>
                          <strong>{c._source?.company?.years_with_cphi}</strong>
                          <span>YRS</span>
                        </S.Mspan>
                      ) : (
                        ""
                      )}
                      {c._source?.company?.no_of_certifications ? (
                        <S.Mspan>
                          <strong>
                            {c._source?.company?.no_of_certifications}
                          </strong>
                          <span>Certificates</span>
                        </S.Mspan>
                      ) : (
                        ""
                      )}
                      {c._source?.company?.no_of_employees ? (
                        <S.Mspan>
                          <strong>{c._source?.company?.no_of_employees}</strong>
                          <span>EMPL</span>
                        </S.Mspan>
                      ) : (
                        ""
                      )}
                      {c._source?.company?.company_type &&
                      typeof c._source?.company?.company_type == "object"
                        ? c._source?.company?.company_type.map(
                            (cType: any, i: number) => (
                              <S.Mspan key={i}>{cType}</S.Mspan>
                            )
                          )
                        : c._source?.company?.company_type &&
                          typeof c._source?.company?.company_type == "string"
                        ? c._source?.company?.company_type
                            .split("|")
                            .map((cType: any, i: number) => (
                              <S.Mspan key={i}>{cType}</S.Mspan>
                            ))
                        : null}
                    </S.MspanBody>
                  </Popover.Body>
                </Popover>
              ) : (
                <></>
              )
            }
          >
            <S.CompanyLogo>
              <div className="logo_card">
                <a
                  href={companyUrl}
                  target={getRedirectMode()}
                  onClick={() => addClickImpression(companyUrl)}
                  className="a-none"
                >
                  <img
                    src={`${new URL(c._source.url).origin}${c._source?.company
                      ?.company_thumbnail}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `${
                        new URL(c._source.url).origin
                      }/gfx17/supplier.gif`;
                    }}
                  />
                </a>
              </div>
            </S.CompanyLogo>
          </OverlayTrigger>
        )}
    </S.CardBody>
  );
};

const ContentImage: React.FC<any> = (props) => {
  const { c, primaryColor, config, getRedirectMode, addClickImpression } =
    props;
  const ImageSrc = c._source.image
    ? c._source.image
    : `${new URL(c._source.url).origin}${c._source.product_thumbnail}`;

  return (
    <S.ImageContainer key={c._id}>
      {config.showCardBgBlur === "yes" ? (
        <S.ImageBg
          className="cl-card__pict-bg"
          backgroundImgUrl={`url(${ImageSrc})`}
        ></S.ImageBg>
      ) : null}
      <S.ImageDivContainer
        backgroundImgUrl={
          config.entity === "product"
            ? `url(${new URL(c._source.url).origin}/gfx17/product.gif)`
            : "none"
        }
      >
        <a
          href={c._source.url}
          target={getRedirectMode()}
          onClick={() => addClickImpression(c._source.url)}
          className="a-none"
        >
          <S.ImageDiv
            imgPosition={
              config.showCardBgBlur === "yes" ? "absolute" : "relative"
            }
            showCardBgBlur={
              config.showCardBgBlur === "yes" ? "contain" : "cover"
            }
            backgroundImgUrl={`url(${ImageSrc})`}
            boxShadow={
              config.showCardBgIcon === "yes"
                ? `10px 10px 0px -2px ${primaryColor}`
                : "none"
            }
          ></S.ImageDiv>
        </a>
      </S.ImageDivContainer>
      {config.showCardBgIcon === "yes" ? (
        <S.ImgIconContainer primaryColor={primaryColor}>
          <Icon
            icon={config.imageIcon}
            style={{
              color: config.imageIconColor,
              fontSize: "20px",
            }}
          />
        </S.ImgIconContainer>
      ) : null}
    </S.ImageContainer>
  );
};

export { ContentEntity, ContentImage };
