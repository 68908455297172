import React from "react";
import { getConvertedWatchTime } from "../helper";
import "../iris-recommend.css";
import * as S from "../style";

const VideoEntity: React.FC<any> = (props) => {
  const { c, config, removeTags, getRedirectMode, addClickImpression } = props;
  let country_name = c._source?.company?.country?.split(" ");
  if (country_name) {
    country_name = country_name
      .map((e: any) => e[0].toUpperCase() + e.slice(1).toLowerCase())
      .join(" ");
  }

  return (
    <a
      href={c._source.url}
      target={getRedirectMode()}
      onClick={() => addClickImpression(c._source.url)}
      className="a-none"
    >
      <S.CardBody
        key={c._id}
        style={{
          padding: config.entityImagePosition === "bottom" ? "0" : "25px 0 0",
          flexGrow: 1,
          color: "inherit",
          height: "100%",
        }}
        className="pointer"
      >
        <S.CardTitle
          key={c._id}
          data-toggle="tooltip"
          data-placement="bottom"
          title={removeTags(c._source.title)}
        >
          <span className="author">
            {c._source.author?.slice(0, c._source.author.length - 1)}
          </span>
          <span className="hover-underline">{removeTags(c._source.title)}</span>
        </S.CardTitle>
        <S.TagsWrapper>
          {c._source.site_tags.map((item: any) => (
            <span key={item} className="video-tag">
              {item}
            </span>
          ))}
        </S.TagsWrapper>
      </S.CardBody>
    </a>
  );
};

const VideoImage: React.FC<any> = (props) => {
  const { c, config, getRedirectMode, addClickImpression } = props;
  const ImageSrc = c._source.image
    ? c._source.image
    : `${new URL(c._source.url).origin}${c._source.product_thumbnail}`;

  return (
    <S.ImageContainer key={c._id} className="video-img-container">
      {config.showCardBgBlur === "yes" ? (
        <S.ImageBg
          className="cl-card__pict-bg"
          backgroundImgUrl={`url(${ImageSrc})`}
        ></S.ImageBg>
      ) : null}
      <S.ImageDivContainer
        backgroundImgUrl={
          config.entity === "product"
            ? `url(${new URL(c._source.url).origin}/gfx17/product.gif)`
            : "none"
        }
      >
        <a
          href={c._source.url}
          target={getRedirectMode()}
          onClick={() => addClickImpression(c._source.url)}
          className="a-none"
        >
          <S.ImageDiv
            imgPosition={
              config.showCardBgBlur === "yes" ? "absolute" : "relative"
            }
            showCardBgBlur={
              config.showCardBgBlur === "yes" ? "contain" : "cover"
            }
            backgroundImgUrl={`url(${ImageSrc})`}
          ></S.ImageDiv>
          <S.PlayIcon className="play-button">
            <img
              src="https://static.iris.informa.com/widgets/assets/play.png"
              style={{ borderRadius: "50%", cursor: "pointer" }}
            />
          </S.PlayIcon>

          <S.VideoInfo className="video-insights">
            <span>{getConvertedWatchTime(c._source.watch_time)}</span>
          </S.VideoInfo>
        </a>
      </S.ImageDivContainer>
    </S.ImageContainer>
  );
};

export { VideoEntity, VideoImage };
