import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as S from "../style";

import "../iris-recommend.css";

const PeopleEntity: React.FC<any> = (props) => {
  const { c, config, removeTags } = props;
  const source_types = c._source.type ? c._source.type.split(", ") : [];
  const [firstType] = useState(source_types.length ? source_types.shift() : "");

  return (
    <>
      <S.CardBody
        key={c._id}
        style={{
          padding: "0.8rem",
          color: "inherit",
          height: "100%",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        <S.PeopleCardTitle
          key={c._id}
          data-toggle="tooltip"
          data-placement="bottom"
          title={removeTags(`${firstType}`)}
          style={{
            textAlign: "center",
          }}
          data-tip
          data-for={`${c._id}-people-name`}
        >
          {removeTags(`${firstType}`)}
        </S.PeopleCardTitle>
        <ReactTooltip id={`${c._id}-people-name`}>
          {removeTags(`${c._source.type}`)}
        </ReactTooltip>
        <S.TextWrapperPeople
          data-toggle="tooltip"
          data-placement="bottom"
          title={removeTags(c._source.job_title)}
          data-tip
          data-for={`${c._id}-people-job`}
        >
          {removeTags(c._source.job_title)}{" "}
          {c._source.company
            ? `${c._source.job_title ? "at" : ""} ${removeTags(
                c._source.company
              )}`
            : ""}
        </S.TextWrapperPeople>
        <ReactTooltip id={`${c._id}-people-job`}>
          {removeTags(c._source.job_title)}{" "}
          {c._source.company
            ? `${c._source.job_title ? "at" : ""} ${removeTags(
                c._source.company
              )}`
            : ""}
        </ReactTooltip>
      </S.CardBody>
      <S.EntityMtag
        className="m-tag"
        entitytagbackground={config.skin}
        entitytagfont={config.imageIconColor}
      >
        Connect
      </S.EntityMtag>
    </>
  );
};

const PeopleImage: React.FC<any> = (props) => {
  const { c, config, getRedirectMode, addClickImpression } = props;
  const source_types = c._source.type ? c._source.type.split(", ") : [];

  const defaultTypes = ["Attendee", "Exhibitor", "Speaker", "VIP", "Visitor"];
  const assetUrl = "https://static.iris.informa.com/widgets/dev/v3/assets/";
  const ImageSrc = c._source.company_logo
    ? c._source.company_logo
    : `${new URL(assetUrl + "no_company.png")}`;

  return (
    <div key={c._id}>
      <S.PeopleImgContainer key={c._id}>
        {config.showCardBgBlur === "yes" ? (
          <S.ImageBg
            className="cl-card__pict-bg"
            backgroundImgUrl={`url(${c._source.company_logo})`}
          ></S.ImageBg>
        ) : null}
        <S.CircleImage>
          <S.PeopleImageDivContainer
            backgroundImgUrl={
              config.entity === "product"
                ? `url(${new URL(c._source.url).origin}/gfx17/product.gif)`
                : "none"
            }
          >
            <a
              href={c._source.url}
              target={getRedirectMode()}
              onClick={() => addClickImpression(c._source.url)}
              className="a-none"
            >
              <S.PeopleImgDiv
                imgPosition={
                  config.showCardBgBlur === "yes" ? "absolute" : "relative"
                }
                backgroundImgUrl={`url(${ImageSrc})`}
              ></S.PeopleImgDiv>
            </a>
          </S.PeopleImageDivContainer>
          <S.TitleTags showCursor={source_types.length ? "pointer" : "default"}>
            {source_types?.length ? (
              <S.MultiDiv>
                {source_types.forEach((e: any, index: number) => {
                  if (defaultTypes.includes(e)) {
                    return (
                      <div key={`${e}-${c._id}-${index}`}>
                        <S.MultiTag
                          className="spin animated"
                          key={`${e}-${c._id}-${index}`}
                          data-tip
                          data-for={`${e}-${c._id}=${index}`}
                          src={`${assetUrl}${e}.png`}
                          animationDelay={`${index * 0.2}s`}
                        />
                        <ReactTooltip
                          id={`${e}-${c._id}=${index}`}
                          place="left"
                        >
                          {e}
                        </ReactTooltip>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </S.MultiDiv>
            ) : null}
          </S.TitleTags>
        </S.CircleImage>
      </S.PeopleImgContainer>
    </div>
  );
};

export { PeopleEntity, PeopleImage };
