import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parser from "html-react-parser";
import jsonLogic from "json-logic-js";
import React, { useCallback, useEffect, useState } from "react";
import { decryptHTML } from "../../services/utils";
import { Data } from "../../types";
import { getNewDimensions } from "../helper";
import * as S from "../style";
import { defaultHtml } from "./constants";

const CryptoJS = require("crypto-js");

let sendAdImp = true;

const PopUpWidget: React.FC<any> = (props) => {
  const { config } = props;
  const daystoshow = config.daysToShow
    ? config.daysToShow
    : '["0", "1", "2", "3", "4", "5", "6"]';

  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const [popupData, setPopupData] = useState({
    imageUrl: "",
    targetUrl: "",
    title: "",
    description: "",
    uploadHtml: false,
    htmlContent: "",
  });
  const [isShowErrorMsg, setIsShowErrorMsg] = useState<boolean>(false);
  const [isDesktopView, setIsDesktopView] = useState<boolean>(
    window.innerWidth > 500
  );
  const delay = config.timeDelay ? +config.timeDelay + 1 : 1;
  const computeNewDimensions = useCallback(getNewDimensions, []);

  useEffect(() => {
    if (config.adminCenter === "yes") {
      getSegments();
    } else {
      new Promise((resolve) => setTimeout(resolve, delay * 1000)).then(
        async () => {
          if (
            !(
              document.cookie.includes("_iiris_popup_ad") ||
              document.cookie.includes(config.cookieName)
            ) &&
            JSON.parse(daystoshow).includes(new Date().getDay().toString())
          ) {
            await getSegments();
          }
        }
      );
    }
  }, [isDesktopView]);

  useEffect(() => {
    function handleResize() {
      setIsDesktopView(window.innerWidth > 500);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const closePopUp = (e: any) => {
    // Stop event propagation.
    e.stopPropagation();
    e.preventDefault();
    const date = new Date();
    setPopupIsVisible(false);
    if (config.cookieChoice === "once") {
      const cookieName =
        config.adminCenter === "yes" ? config.cookieName : "_iiris_popup_ad";
      document.cookie = `${cookieName} =; max-age = ${
        86400 -
        (date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds())
      }; path=/;`;
    }
  };

  //Functions to check if any of the recommendation element is present in viewport
  // const isInViewport = (elements: NodeListOf<HTMLElement>) => {
  //   let result = false;
  //   for (const element of elements) {
  //     result = checkIsInViewPort(element) || result;
  //     if (result) {
  //       break;
  //     }
  //   }
  //   return result;
  // };

  // const checkIsInViewPort = (elem: HTMLElement) => {
  //   const bounding = elem.getBoundingClientRect();
  //   return (
  //     bounding.top >= 0 &&
  //     bounding.left >= 0 &&
  //     Math.floor(bounding.bottom) <=
  //       (window.innerHeight || document.documentElement.clientHeight) &&
  //     Math.floor(bounding.right) <=
  //       (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // };

  //Tracking Ad Impressions
  const adImpression = (zone: string, redirectUrl?: string) => {
    if (window.IIRISTracker && window.IIRISTracker.trackImpression) {
      const impressionId = config.imageUrl || window.location.href;
      const bannerId = config.bannerId;
      const campaignId = config.campaignId;
      const advertiserId = config.advertiserId;

      let newImpressionId;
      try {
        newImpressionId = new URL(impressionId);
        newImpressionId.searchParams.append("widgetId", config.id);
        newImpressionId.searchParams.append(
          "rule",
          config.ruleNo ? config.ruleNo : 0
        );
        newImpressionId.searchParams.append("iiris-ref", "target");
      } catch {
        newImpressionId = impressionId.replaceAll(" ");
        newImpressionId += `?widgetId=${config.id}&rule=${
          config.ruleNo ? config.ruleNo : 0
        }&iiris-ref=target`;
      }

      const data: Data = {
        impressionId: newImpressionId.toString(),
        bannerId: bannerId,
        campaignId: campaignId,
        advertiserId: advertiserId,
        zoneId: zone,
      };

      if (zone === "click") {
        data.targetUrl = popupData.targetUrl || popupData.imageUrl;
        const ctrEvt = new CustomEvent("recommendCTREvent", {
          detail: { target_url: redirectUrl },
        });
        window.dispatchEvent(ctrEvt);
      } else if (zone === "viewport") {
        const viewportEvt = new CustomEvent("iiris_recommendViewportEvent", {
          detail: { impressionId: newImpressionId },
        });
        window.dispatchEvent(viewportEvt);
      }

      window.IIRISTracker.trackImpression(data);
    }
  };

  const getRedirectMode = () => {
    const redirectionMode = config.redirectMode;
    let mode;
    if (redirectionMode === "new") {
      mode = "_blank";
    } else if (redirectionMode === "same") {
      mode = "_self";
    }
    return mode;
  };

  const addClickImpression = (sourceUrl: string) => {
    let redirectUrl;
    try {
      redirectUrl = new URL(sourceUrl);
      redirectUrl.searchParams.append("iiris-ref", "target");
    } catch (e) {
      redirectUrl = sourceUrl + "?iiris-ref=target";
    }
    // Ad Impression Event OnClick
    adImpression("click", redirectUrl.toString());
  };

  const setDefaultRule = () => {
    let image, width, height, selectedWidth;
    if (config.adminCenter) {
      image = config.mobilePreview
        ? config.mobileViewImageUrl || config.imageUrl
        : config.imageUrl;
    } else {
      image = isDesktopView
        ? config.imageUrl
        : config.mobileViewImageUrl || config.imageUrl;
    }
    setPopupData({
      imageUrl: image,
      targetUrl: config.targetUrl,
      title: config.popupTitle,
      description: config.description,
      uploadHtml: config.uploadHtml,
      htmlContent: decryptHTML(config.htmlContent),
    });
    if (isDesktopView) {
      [width, height] = config.uploadHtml
        ? [config.widgetwidth, config.widgetheight]
        : config.desktopwidgetsize.split("X");
      selectedWidth = config.desktopscreen.split("x")[0];
    } else {
      [width, height] = config.uploadHtml
        ? [config.widgetwidth, config.widgetheight]
        : config.mobilewidgetsize
        ? config.mobilewidgetsize.split("X")
        : config.desktopwidgetsize.split("X");
      selectedWidth = config.mobilescreen.split("x")[0];
    }
    if (window.innerWidth < selectedWidth) {
      [width, height] = computeNewDimensions(
        parseInt(width),
        parseInt(height),
        parseInt(selectedWidth)
      );
    }
    config.imageWidth = parseInt(width);
    config.imageHeight = parseInt(height);
    if (config.uploadHtml) {
      config.imageUrl = config.htmlContent;
    }
    checkCampaignValidity();
  };

  const checkCampaignValidity = () => {
    if (!config.adminCenter) {
      const now = new Date();
      const _endDate = new Date(config.endDate);
      const now_utc = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        0
      );
      const endDate_utc = Date.UTC(
        _endDate.getFullYear(),
        _endDate.getMonth(),
        _endDate.getDate(),
        _endDate.getHours(),
        _endDate.getMinutes(),
        0
      );
      if (config.endDate && now_utc > endDate_utc) {
        setPopupIsVisible(false);
      } else {
        setPopupIsVisible(true);
        //Tracking Viewport event for ad impression
        if (sendAdImp) {
          // Ad Impression event when recommendation is seen by the user
          sendAdImp = false;
          adImpression("viewport");
        }
      }
    } else {
      setPopupIsVisible(true);
      //Tracking Viewport event for ad impression
      if (sendAdImp) {
        // Ad Impression event when recommendation is seen by the user
        sendAdImp = false;
        adImpression("viewport");
      }
    }
  };

  const getSegments = async () => {
    let rules: any[], token;
    const decryptKey = "secret_key_iiris_recommend";
    if (config.configId) {
      rules = config.rule;
      token = config.token;
    } else {
      const _ciphertext = CryptoJS.AES.decrypt(
        config.rule.toString(),
        decryptKey
      );
      const c_text = _ciphertext.toString(CryptoJS.enc.Utf8);
      const _rules = JSON.parse(c_text);
      rules = _rules.config;
      token = _rules.token;
    }

    if (!config.userid) {
      while (window.IIRISTracker?.getDUId() == undefined) {
        await new Promise((r) => setTimeout(r, 5)); //wait for tracker to load
      }
    }

    for (const rule of rules) {
      try {
        let segmentResponse;
        if (JSON.stringify(rule).includes("batch")) {
          segmentResponse = await fetch(
            `https://cdp-eu01.in.treasuredata.com/cdp/lookup/collect/segments?version=2&token=${token}&key.first_party_tracking_id=${
              config.userid || window.IIRISTracker?.getDUId()
            }`
          );
        } else {
          segmentResponse = await fetch(
            `https://cdp-eu01.in.treasuredata.com/cdp/lookup/collect/segments?version=2&token=${token}&key.td_client_id=${
              config.userid || window.IIRISTracker?.getDUId()
            }`
          );
        }

        const response = await segmentResponse.json();
        if (response && response[0] && rules.length) {
          const computeRules: boolean[] = [];
          let ruleConfig: any = null;
          response[0].values.forEach((value: any) => {
            let result: any;
            if (JSON.stringify(rule).includes("batch")) {
              result = {
                segmentId: [
                  {
                    batch: value,
                  },
                ],
              };
            } else {
              result = {
                segmentId: [
                  {
                    realTime: value,
                  },
                ],
              };
            }
            let output = rules.find((e) => jsonLogic.apply(e.tree, result));
            if (!output) {
              const result1 = { segmentId: value };
              output = rules.find((e) => jsonLogic.apply(e.tree, result1));
            }
            if (output) {
              computeRules.push(true);
              if (ruleConfig === null) ruleConfig = output;
            } else if (!output && !JSON.stringify(rule).includes(value)) {
              computeRules.push(true);
            } else computeRules.push(false);
          });
          const valid = computeRules.every((item) => item);
          if (valid && ruleConfig !== null) {
            rules.forEach((rule, index) => {
              if (rule.id === ruleConfig.id) {
                config.ruleNo = index + 1;
              }
            });
            let image;
            if (config.adminCenter) {
              image = config.mobilePreview
                ? ruleConfig.mobileViewImageUrl || ruleConfig.imageUrl
                : ruleConfig.imageUrl;
            } else {
              image = isDesktopView
                ? ruleConfig.imageUrl
                : ruleConfig.mobileViewImageUrl || ruleConfig.imageUrl;
            }
            setPopupData({
              imageUrl: image,
              targetUrl: ruleConfig.targetUrl,
              title: ruleConfig.title,
              description: ruleConfig.description,
              uploadHtml: ruleConfig.uploadhtml,
              htmlContent: decryptHTML(ruleConfig.htmlCode),
            });
            config.imageUrl = ruleConfig.uploadHtml
              ? ruleConfig.htmlContent
              : image;
            let width, height, selectedWidth;
            if (isDesktopView) {
              [width, height] = ruleConfig.uploadhtml
                ? [ruleConfig.widgetwidth, ruleConfig.widgetheight]
                : ruleConfig.desktopwidgetsize.split("X");
              selectedWidth = config.desktopscreen.split("x")[0];
            } else {
              [width, height] = ruleConfig.uploadhtml
                ? [ruleConfig.widgetwidth, ruleConfig.widgetheight]
                : ruleConfig.mobilewidgetsize
                ? ruleConfig.mobilewidgetsize.split("X")
                : ruleConfig.desktopwidgetsize.split("X");
              selectedWidth = config.desktopscreen.split("x")[0];
            }
            if (window.innerWidth < selectedWidth) {
              [width, height] = computeNewDimensions(
                parseInt(width),
                parseInt(height),
                parseInt(selectedWidth)
              );
            }
            config.imageWidth = parseInt(width);
            config.imageHeight = parseInt(height);
            if (!config.imageUrl) {
              setIsShowErrorMsg(true);
            }
            checkCampaignValidity();
            return true;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (config.defaultRuleEnabled && config.defaultRuleEnabled !== "no") {
      setDefaultRule();
    } else {
      setIsShowErrorMsg(true);
    }
  };

  return popupIsVisible && (popupData.imageUrl || popupData.htmlContent) ? (
    <div className="iiris-popup-overlay">
      <S.PopUpAds
        border={config.showBorder}
        bordercolor={config.borderColor}
        buttontopmargin={config.buttonTopMargin}
        buttonrightmargin={config.buttonRightMargin}
        showbuttonborder={config.showbuttonBorder}
        bordersize={config.borderSize}
        buttonsize={config.buttonSize}
        buttoncolor={config.buttonColor}
        buttonbordercolor={config.buttonBorderColor}
        buttonfill={config.buttonFill}
        width={`${Math.min(config.imageWidth, window.innerWidth)}px`}
        height={`${config.imageHeight}px`}
        onMouseEnter={() => {
          adImpression("onMouseEnter");
        }}
        className="overlay-popup-content"
      >
        {popupData.uploadHtml ? (
          <div className="html-body">
            <div className="iiris-popup-close-btn">
              <FontAwesomeIcon
                className="close-popup"
                icon={faClose}
                onClick={closePopUp}
              />
            </div>
            {parser(popupData.htmlContent || defaultHtml)}
          </div>
        ) : (
          <div className="iiris-popup-body">
            <div className="iiris-popup-close-btn">
              <FontAwesomeIcon
                className="close-popup"
                icon={faClose}
                onClick={closePopUp}
              />
            </div>
            <a
              href={popupData.targetUrl}
              target={getRedirectMode()}
              onClick={() => addClickImpression(popupData.targetUrl)}
            >
              <img
                src={popupData.imageUrl}
                alt="ad-image"
                width={config.imageWidth}
                height={config.imageHeight}
              />
            </a>
          </div>
        )}
      </S.PopUpAds>
    </div>
  ) : isShowErrorMsg &&
    popupData.imageUrl === "" &&
    config.adminCenter === "yes" ? (
    <div className="flex align-items-center justify-content-center">
      <div>
        <h3 className="no-image flex justify-content-center">No Popup</h3>
        <div>
          <h6 className="flex justify-content-center">
            User ID [{config.userid}] doesn’t match any targeting rule.
          </h6>
        </div>
      </div>
    </div>
  ) : null;
};

export { PopUpWidget };
