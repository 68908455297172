const CryptoJS = require("crypto-js");

//Remove HTML Tags from text
const removeTags = (str: any) => {
  if (str && str.length) {
    str = str.toString();
    str = str.replace(/&amp;/g, "&");
    str = str.replace(/&nbsp;/g, " ");
    str.replace(/(<([^>]+)>)/gi, "");
  }
  return str;
};

//Logic to Show/Hide Date as per Widget Entity
const showFormattedDate = (date: any, publishedDate: any, format: any) => {
  if (date && date.toLowerCase() != "nan") {
    return formatDate(date, format);
  } else if (publishedDate && publishedDate.toLowerCase() != "nan") {
    return formatDate(publishedDate, format);
  } else {
    return;
  }
};

//Logic to format date as per widget configuration
//Input date is expected in format DD/MM/YYYY
const formatDate = (date: any, format: any) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const initialDate = date.split(/\//);

  const mm = initialDate[1];
  const dd = initialDate[0];
  const yyyy = initialDate[2];
  const dateToProcess = new Date([mm, dd, yyyy].join("/"));
  const month = months[dateToProcess.getMonth()];

  if (format.toLowerCase() == "dd/mm/yyyy") {
    return date;
  } else if (format.toLowerCase() == "mm/dd/yyyy") {
    return [mm, dd, yyyy].join("/");
  } else if (format.toLowerCase() == "yyyy/mm/dd") {
    return [yyyy, mm, dd].join("/");
  } else if (format.toLowerCase() == "month dd, yyyy") {
    return month + " " + dd + ", " + yyyy;
  } else if (format == "MMM DD, YYYY") {
    return (
      dateToProcess.toLocaleString("en-US", { month: "short" }).toUpperCase() +
      " " +
      dd +
      ", " +
      yyyy
    );
  } else if (format == "Mmm DD, YYYY") {
    return (
      dateToProcess.toLocaleString("en-US", { month: "short" }) +
      " " +
      dd +
      ", " +
      yyyy
    );
  }
};

const decryptHTML = (encryptedCode: string) => {
  const _ciphertext = CryptoJS.AES.decrypt(
    encryptedCode,
    "secret_code_iiris_target"
  );
  const c_text = _ciphertext.toString(CryptoJS.enc.Utf8);
  return c_text;
};

export { decryptHTML, formatDate, removeTags, showFormattedDate };
